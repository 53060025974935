<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vs-card class="container">
      <div style="padding: 0 1rem">
        <h1 class="mt-5 text-center">سجل طلبات المتاجر</h1>

        <div class="vx-row mt-8 justify-center">
          <div class="vx-col my-3 sm:w-1/3 w-full">
            <div class="mb-2"><label for="">إسم المتجر</label></div>
            <v-select v-model="filter.store" label="name" :options="stores" data-vv-as="إسم المتجر" v-validate="'required'"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" name="storename" class="w-full"></v-select>
              <span class="text-danger text-sm" v-show="errors.has('storename')">{{
                errors.first("storename")
              }}</span>
          </div>
          <div class="vx-col my-3 sm:w-1/3 w-full">
            <div class="mb-2"><label for="">من</label></div>
            <datepicker name="fromDate" data-vv-as="الحقل" v-validate="'required'" class="w-full" valueType="format" :format="'yyyy-MM-dd'" v-model="filter.from" tabindex="2"></datepicker>
            <span class="text-danger text-sm" v-show="errors.has('fromDate')">{{
                errors.first("fromDate")
              }}</span>
          </div>
          <div class="vx-col my-3 sm:w-1/3 w-full">
            <div class="mb-2"><label for="">إلى</label></div>
            <datepicker name="toDate" data-vv-as="الحقل" v-validate="'required'" class="w-full" valueType="format" :format="'yyyy-MM-dd'" v-model="filter.to" tabindex="2"></datepicker>
            <span class="text-danger text-sm" v-show="errors.has('toDate')">{{
                errors.first("toDate")
              }}</span>
          </div>
          <div class="vx-col my-3 w-full text-center">
            <vs-button class="" @click="getData" color="primary" type="filled">بحث</vs-button>
          </div>
        </div>
      </div>
    </vs-card>

    <div id="data-list-list-view" class="data-list-container" v-show="selected_store">

      <vs-table ref="table" multiple v-model="selected" :max-items="itemsPerPage" search :data="orders">
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <!-- ACTION - DROPDOWN -->
            <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4">
              <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
                <span class="mr-2">العمليات</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>

              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>حذف</span>
                  </span>
                </vs-dropdown-item>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>أرشيف</span>
                  </span>
                </vs-dropdown-item>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>طباعة</span>
                  </span>
                </vs-dropdown-item>

              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{
                orders.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : orders.length
              }}
                of {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th sort-key="id">رقم الطلب</vs-th>
          <vs-th sort-key="total">المبلغ الإجمالي</vs-th>
          <vs-th sort-key="created_at">تاريخ التوصيل</vs-th>
          <vs-th>للزبون</vs-th>
          <vs-th sort-key="order_status_id">حالة الطلب</vs-th>
          <vs-th>العمليات</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.id }}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{ tr.total_amount }}LYD</p>
              </vs-td>

              <vs-td>
                <vs-chip class="product-order-status">
                  {{ tr.delivery_date }}
                </vs-chip>
              </vs-td>

              <vs-td>
                {{ tr.user.name }}
              </vs-td>

              <vs-td>
                <vs-chip transparent :color="getOrderStatusColor(tr.statu.status)" class="product-order-status">
                  {{ tr.statu.status }}</vs-chip>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  @click.stop="editData(tr)" />

                <!-- <feather-icon icon="ArchiveIcon" svgClasses="w-5 h-5 hover:text-warning stroke-current" class="ml-2"
                  @click.stop="confirm(tr.id)" /> -->
                <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-success stroke-current" class="ml-2"
                  @click="printInvoice(tr)" />

              </vs-td>
            </vs-tr>
          </tbody>

        </template>
      </vs-table>
      <vs-pagination
      class="mt-4"
      :total="lastPage"
      v-model="current_Page"
      @change="getData"
    ></vs-pagination>
    </div>
  </div>
</template>

<script>
  import vSelect from "vue-select";
  import Datepicker from "vuejs-datepicker";
  import {
    mapActions
  } from "vuex";

  export default {
    components: {
      vSelect,
      Datepicker,
    },
    data() {
      return {
        selected_store: false,
        selected: [],
        orders: [],
        stores: [],
        filter: {
          store: '',
          from: '',
          to: '',
        },
        current_Page: 1,
        itemsPerPage: 10,
        lastPage: '',
        total: '',

      };
    },
    computed: {
      currentPage() {
        if (this.isMounted) {
          return this.$refs.table.currentx;
        }
        return 0;
      },
      queriedItems() {
        return this.$refs.table ?
          this.$refs.table.queriedResults.length :
          this.orders.length;
      },
    },
    methods: {
      ...mapActions('orders', [
        'fetchClassbOrders',
      ]),
      ...mapActions('stores', [
        'fetchStores',
      ]),
      getData() {
        this.$validator.validateAll()
          .then((result) => {
            if (result) {
              let data = new FormData();
              data.set('page', this.current_Page)
              data.set('store_id', this.filter.store.id)
              data.set('from', this.formatDate(this.filter.from))
              data.set('to', this.formatDate(this.filter.to))

              this.fetchClassbOrders(data)
                .then((response) => {
                  this.orders = response.data.data.data
                  this.total = response.data.data.total
                  this.lastPage = response.data.data.last_page
                  this.current_Page = response.data.data.current_page
                })
                this.selected_store = true
            }
          })
      },
      printInvoice(tr) {
        localStorage.setItem('order_id', tr.id);
        this.$router.push({
          name: 'stores-management-invoice'
        })
      },
      editData(tr) {
        this.$router.push({
          name: 'stores-management-edit-order',
          params: {
            id: tr.id
          }
        })
      },
      confirm(id) {
        this.order_id = id
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: 'تأكيد',
          text: 'هل انت متأكد من أرشفة هذا الطلب؟',
          acceptText: 'تأكيد',
          cancelText: 'الغاء',
          accept: this.deleteData,
        })
      },
      deleteData() {
        
      },

      getOrderStatusColor(status) {
        if (status == "قيد المراجعة") return "warning";
        if (status == "تم التوصيل") return "success";
        if (status == "تم الرفض") return "danger";
        if (status == "تم الإلغاء") return "danger";
        if (status == "قيد التوصيل") return "primary";
        if (status == "قيد التجهيز") return "primary";
        if (status == "معلق") return "#f7cb73";
        return "primary";
      },
    },
    mounted() {

    },
    created() {
      this.fetchStores()
        .then((response) => {
          this.stores = response.data.data
        })

    },
  };

</script>
